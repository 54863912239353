import axios from "axios";
import { decrypt } from "../helpers/encryptor";
import { LOCAL_STORAGE_KEYS } from "../helpers/localStorageKeys";
import { ROUTES } from "./../helpers/routes";
import CONFIG from "./../helpers/config";
import { appInsights } from "../components/AppInsight/AppInsight";

export const token = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  if (!token) {
    return null;
  }

  return decrypt(token);
};

const axiosInstance = axios.create({
  // @ts-ignore
  baseURL: CONFIG.BASE_URL,
});

const mockAxiosInstance = axios.create({
  // @ts-ignore
  baseURL: "http://localhost:3001/api",
});

axiosInstance.interceptors.request.use(
  function (config) {
    // @ts-ignore
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      "Content-Security-Policy":
        "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      Authorization: `Bearer ${token()}`,
    };

    // If there is no token, delete if from the header before making a request
    if (!token()) {
      delete config.headers.Authorization;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(undefined, async function (error) {
  const { baseURL, headers, method, url } = error?.config;
  appInsights.trackException({
    exception: new Error(error),
    properties: {
      fileName: "apiMethods.ts",
      baseURL,
      headers,
      method,
      url,
      response: error?.response?.data,
    },
  });
  if (error?.response?.status === 500) {
    return Promise.reject("Server Error");
  }
  // console.log(error.response.request.responseURL);
  if (error?.response?.status === 401 && token()) {
    window.location.replace(ROUTES.LOGIN);
  }
  return Promise.reject(error);
});

export const getData = async (url: string) => {
  const { data } = await axiosInstance({
    method: "get",
    url,
  });
  return data;
};

export const postData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data;
};

export const patchData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "PATCH",
    url,
    data: reqBody,
  });
  return data;
};
export const putData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
  return data;
};

export const deleteData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data;
};

export const getMockData = async (url: string) => {
  const { data } = await mockAxiosInstance({
    method: "get",
    url,
  });
  return data;
};
